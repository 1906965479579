import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header bg-gradient-to-r from-primary-lighter to-primary" }
const _hoisted_2 = { class: "container mx-auto" }
const _hoisted_3 = { class: "header__block py-10" }
const _hoisted_4 = { class: "main bg-body-light pb-20" }
const _hoisted_5 = { class: "container mx-auto" }
const _hoisted_6 = { class: "flex flex-wrap -mx-4" }
const _hoisted_7 = { class: "w-full mt-8 lg:mt-0 lg:w-3/12 px-4" }
const _hoisted_8 = { class: "w-full lg:w-9/12 px-4 space-y-8" }
const _hoisted_9 = { class: "p-4 sm:p-6 bg-white rounded-lg border border-opacity-70" }
const _hoisted_10 = { class: "-mx-2 sm:-mx-4 flex flex-wrap items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_booking_steps = _resolveComponent("booking-steps")!
  const _component_navigator = _resolveComponent("navigator")!
  const _component_flight_extra_info = _resolveComponent("flight-extra-info")!
  const _component_Railway_tarif = _resolveComponent("Railway_tarif")!
  const _component_contact_info = _resolveComponent("contact-info")!
  const _component_passengers = _resolveComponent("passengers")!
  const _component_loader = _resolveComponent("loader")!
  const _component_alert = _resolveComponent("alert")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TheHeader)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_booking_steps, { "active-tab": "1" }),
      _createVNode(_component_navigator),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_flight_extra_info, {
              "train-res": _ctx.trainRes,
              "search-res": _ctx.searchRes,
              "search-res-pass-route": _ctx.searchResPassRoute
            }, null, 8, ["train-res", "search-res", "search-res-pass-route"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Railway_tarif, { "train-res": _ctx.trainRes }, null, 8, ["train-res"]),
            _createVNode(_component_contact_info, { user: _ctx.currentUser }, null, 8, ["user"]),
            _createVNode(_component_passengers),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", {
                  class: "p-3 mx-2 sm:mx-4 flex-auto rounded border hover:text-white hover:bg-primary hover:border-transparent transition",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancelBooking && _ctx.onCancelBooking(...args)))
                }, _toDisplayString(_ctx.$t("Basic.Cancel")), 1),
                _createElementVNode("button", {
                  class: "p-3 mx-2 sm:mx-4 flex-auto rounded flex items-center justify-center text-white bg-primary space-x-3 hover:bg-opacity-90 transition",
                  "x-data": "",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onBookRequest(_ctx.trainRes)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("Basic.ToBook")), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_loader, {
      message: _ctx.loadingMessage,
      show: _ctx.loading
    }, null, 8, ["message", "show"]),
    _createVNode(_component_alert, {
      message: _ctx.alertMessage,
      "show-alert": _ctx.alertShow,
      "show-ok-btn": "true",
      onOnOkClickEvent: _ctx.onOkClickEventHandler
    }, null, 8, ["message", "show-alert", "onOnOkClickEvent"]),
    _createVNode(_component_TheFooter)
  ], 64))
}