
import { computed, defineComponent, watchEffect } from "vue";
import Alert from "@/components/general/alert_popup.vue";
import Loader from "@/components/general/loader.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import Navigator from "@/components/flight/navigator.vue";
import FlightExtraInfo from "@/components/railway_booking/extra_info.vue";
import ContactInfo from "@/components/railway_booking/contact_info.vue";
import BookingSteps from "@/components/flight_booking/steps.vue";
import Passengers from "@/components/railway_booking/passenger_form.vue";
import { TicketBooking } from "@/models/order_modes";
import { User } from "@/models/auth/user_models";
import { LocalDb } from "@/repository/localdb";
import { AvioneMessage } from "@/constants/error_messages";
import Swal from "sweetalert2";
import { isStringNullOrEmpty } from "@/helpers/general_helper";
import flightBookingController from "@/composables/flight_booking";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";
import Railway_tarif from "@/components/railway_booking/railway_tarif.vue";
import { trainTypeApi } from "@/services/railway_train_type";
import { doOrderApi } from "@/services/railway_do_order";
import { searchApi } from "@/services/railway_search";
import api from "@/services/api_request";
import { DetectData } from "@/constants/detect";

export default defineComponent({
  name: "FlightBooking",
  components: {
    Railway_tarif,
    TheFooter,
    TheHeader,
    Navigator,
    BookingSteps,
    FlightExtraInfo,
    Loader,
    ContactInfo,
    Passengers,
    Alert,
  },

  setup() {
    const { state } = flightBookingController();
    // const {state, generatePassengers} = flightBookingController();
    const { doOrder } = DetectData;
    const { savedoOrder, doOrderForm } = doOrderApi;
    const { searchRes, searchResPassRoute } = searchApi;
    const { trainRes, car } = trainTypeApi;
    const { t } = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const currentUrl = computed(
      () =>
        router.resolve({
          name: "home",
          params: { lang: route.params.lang },
        }).href
    );

    useHead({
      title: computed(() => t("pages.home.title")),
      meta: [
        {
          name: `description`,
          content: computed(() => t("pages.home.description")),
        },
        {
          name: `og:title`,
          content: computed(() => t("pages.home.title")),
        },
        {
          name: `og:description`,
          content: computed(() => t("pages.home.description")),
        },
        {
          name: `og:type`,
          content: "website",
        },
        {
          name: `og:url`,
          content: computed(() => `https://test-frontend.avione.uz${currentUrl.value}`),
        },
      ],

      link: computed(() =>
        [
          {
            rel: "canonical",
            href: `https://test-frontend.avione.uz${currentUrl.value}`,
          },
        ].concat(
          [
            { lang: "uz", prefix: "uz" },
            { lang: "en", prefix: "en" },
            { lang: "ru", prefix: "" },
          ]
            .filter((l) => route.params.lang !== l.prefix)
            .map((l) => {
              return {
                rel: "alternate",
                hreflang: l.lang,
                href:
                  "https://test-frontend.avione.uz" +
                  router.resolve({
                    name: "home",
                    params: { lang: l.prefix },
                  }).href,
              };
            })
        )
      ),
    });
    const tokenData = LocalDb.getToken();

    watchEffect(() => {
      if (trainRes.value) {
        state.loading.value = false;
        state.loadingMessage.value = "";
      }
    });

    return {
      ...state,
      doOrder,
      trainRes,
      searchRes,
      // eslint-disable-next-line vue/no-dupe-keys
      tokenData,
      car,
      savedoOrder,
      doOrderForm,
      searchResPassRoute,
    };
  },
  data() {
    return {
      validate: false,
      isLoginOpen: false,
      alertShow: false,
      alertMessage: "",
      bookingResultShow: false,
      currentUser: {} as User | null,
      tokenData: {}, // searchParameter: {} as SearchParameter | null
    };
  },

  async created() {

    this.loading = true;
    this.loadingMessage = this.$t("CheckAviabilty.TariffAvailabilityCheck");
    const ticket: TicketBooking | null = LocalDb.getBookingTicket();
    if (ticket) {
      // this.ticketBooking = ticket;
      this.currentUser = LocalDb.getCurrentUser();
      // this.searchParameter = LocalDb.getSearchParameters();
      // if (this.searchParameter)
      //   this.generatePassengers(this.searchParameter.selectedPassengers);
    } else {
      // window.location.href = '/';
    }
  },
  methods: {
    onCancelBooking: function() {
      LocalDb.removeBookingTicket();
      this.$router.push({
        path: "/",
        params: { lang: this.$route.params.lang },
      });
    },

    onBookRequest: async function(trainRes: any) {
      try {
        this.validateAll();

        // parsing passengers array to normal ordered array
        const newPassengersArr = [];
        for (let index in this.doOrderForm.passengers) {
          newPassengersArr.push(this.doOrderForm.passengers[index]);
        }

        this.doOrderForm.ordersRequest[0].train.number = trainRes.number;
        const data = { ...this.doOrderForm, passengers: newPassengersArr };
        this.loading = true;
        const response: any = await api
          .post("/railway/do-order", data, {
            headers: { Authorization: "bearer " + this.tokenData?.token },
          })
          .then((res) => {
            LocalDb.saveMyFlightOrder(res?.order);
            this.doOrder = res.order?.orderId;
            (this.loading = false), (this.loadingMessage = "");
            this.$router.push({
              name: "railway_booking_payment",
              params: {
                id: res.order?.orderId,
                lang: this.$route.params.lang,
              },
            });
          });
      } catch (e) {
        this.validate = true;
        (this.loading = false), (this.loadingMessage = "");
        await Swal.fire("", e.message, "warning");
      }
    },

    validateAll: function() {
      this.validate = false;
      // this.bookingResultShow = false;
      // this.validateTariff();
      // this.validateBookingTicket();
      // this.validateCurrentUser();
      this.validatePassenger();
    },

    validatePassenger: function() {
      for (const index in this.doOrderForm.passengers) {
        if (isStringNullOrEmpty(this.doOrderForm.passengers[index].firstName))
          throw new Error(this.$t(AvioneMessage.PASSENGER_FIRSTNAME_REQUIRED));
        if (isStringNullOrEmpty(this.doOrderForm.passengers[index].lastName))
          throw new Error(this.$t(AvioneMessage.PASSENGER_LASTNAME_REQUIRED));
        if (
          isStringNullOrEmpty(
            this.doOrderForm.passengers[index].birthDate.toString()
          )
        )
          throw new Error(this.$t(AvioneMessage.PASSENGER_BIRTHDATE_REQUIRED));
        if (
          isStringNullOrEmpty(this.doOrderForm.passengers[index].phoneNumber) &&
          !this.doOrderForm.passengers[index].parentDocNumber
        )
          throw new Error(
            this.$t(AvioneMessage.PASSENGER_PHONENUMBER_REQUIRED)
          );
        // if (!this.doOrderForm.passengers[index].gender)
        //   throw new Error(this.$t(AvioneMessage.PASSENGER_GENDER_REQUIRED));
        if (!this.doOrderForm.passengers[index].citizenship) {

          throw new Error(
            this.$t(AvioneMessage.PASSENGER_CITIZENSHIP_REQUIRED)
          );
        }
        if (
          !this.doOrderForm.passengers[index].email &&
          !this.doOrderForm.passengers[index].parentDocNumber
        ) {
          throw new Error(this.$t(AvioneMessage.PASSENGER_EMAIL_REQUIRED));
        }
        if (!this.doOrderForm.passengers[index].document.issue)
          throw new Error(this.$t(AvioneMessage.PASSENGER_ISSUE_REQUIRED));
        if (!this.doOrderForm.passengers[index].document.expire)
          throw new Error(this.$t(AvioneMessage.PASSENGER_EXPIRE_REQUIRED));
        if (!this.doOrderForm.passengers[index].document.type)
          throw new Error(
            this.$t(AvioneMessage.PASSENGER_DOCUMENTTYPE_REQUIRED)
          );
        if (
          isStringNullOrEmpty(
            this.doOrderForm.passengers[index].document.number
          )
        )
          throw new Error(
            this.$t(AvioneMessage.PASSENGER_PASSPORNUMBER_REQUIRED)
          );
      }
    },
  },

  // validateBookingTicket: function () {
  //   if (this.ticketBooking == undefined)
  //     throw new Error(this.$t(AvioneMessage.TARIF_NOT_SELECTED));
  // },

  // validateTariff: function () {
  //   if (this.tariffs && this.tariffs.status == ResponseStatusType.Ok) {
  //     if (this.selectedTariff === undefined || isStringNullOrEmpty(this.selectedTariff?.segments))
  //       throw new Error(this.$t(AvioneMessage.TARIF_NOT_SELECTED));
  //   }
  // },

  // validateCurrentUser: function () {
  //   if (this.currentUser == null || this.currentUser === undefined)
  //     throw new Error(this.$t(AvioneMessage.CURRENT_USER_NOT_FOUND));
  // },

  // collectBookingInfo: function (): OrderRequest {
  //   if (this.currentUser == null || this.currentUser === undefined)
  //     throw new Error(this.$t(AvioneMessage.CURRENT_USER_NOT_FOUND));
  //
  //   const orderPhone: OrdersPhone = {
  //     number: this.currentUser.phoneNumber,
  //     code: this.currentUser.phoneNumberInfo.code,
  //     extra: this.currentUser.extraPhoneNumber
  //   };
  //
  //   const address: OrdersAddress = {
  //     additional: this.currentUser.address,
  //     city: this.currentUser.city,
  //     zip: this.currentUser.zip,
  //     country: this.currentUser.country?.code,
  //   };
  //
  //   for (let passenger of this.passengers) {
  //     if (passenger.phoneNumber) {
  //       passenger.phoneNumberInfo = getCountryInfoByPhoneNumber(passenger.phoneNumber) ?? undefined;
  //       passenger.phoneNumber = getPhoneNumberInfo(passenger.phoneNumber).getNationalNumber()?.toString();
  //     }
  //     if (passenger.birthDate)
  //       passenger.birthDate = new Date(passenger.birthDate).toLocaleDateString('en-CA');
  //     if (passenger.document.issue)
  //       passenger.document.issue = new Date(passenger.document.issue).toLocaleDateString('en-CA');
  //     if (passenger.document.expire)
  //       passenger.document.expire = new Date(passenger.document.expire).toLocaleDateString('en-CA');
  //   }
  //
  //   const orderRequest: OrderRequest = {
  //     buyId: this.getBuyId(),
  //     ticketOwner: this.ticketBooking.ticketOwner,
  //     phone: orderPhone,
  //     address: address,
  //     emails: [this.currentUser.email],
  //     passengers: this.passengers,
  //     flightDetails: {
  //       departure: this.ticketBooking?.ticket.departure,
  //       arrival: this.ticketBooking?.ticket.arrival
  //     },
  //   };
  //   return orderRequest;
  // },

  // getBuyId: function (): string {
  //   if (this.selectedTariff != null && !isStringNullOrEmpty(this.selectedTariff.segments))
  //     return this.selectedTariff.segments
  //   else if (this.ticketBooking)
  //     return this.ticketBooking.buyId;
  //   return "";
  // },
  //
  // onOkClickEventHandler: function () {
  //   this.alertShow = false;
  //   this.alertMessage = "";
  // },

  // onClosePaymentClickEventHandler: function () {
  //   this.bookingResultShow = false;
  //   this.orderNumber = "";
  //   // this.$router.push({
  //   //   path: '/',
  //   //   params: {
  //   //     lang: this.$route.params.lang
  //   //   }
  //   // });
  // },

  // onPaymentClickEventHandler: function () {
  //
  //   this.$router.push({
  //     name: 'railway_booking_payment',
  //     params: {
  //       id: this.orderNumber,
  //       lang: this.$route.params.lang
  //     }
  //   });
  // }
  // },
});
